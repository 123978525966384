import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from './sesion.service';
import { Observable, catchError, of, tap  } from 'rxjs';
import { environment } from '../../environments/environment';
import { EvidenteRequest } from '../models/validate.model';
import { AuthorizationRequest } from '../models/AuthorizationRequest.model';


const headers: any = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  constructor(public http: HttpClient, private sesion: SesionService) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      throw new Error(error);
    }
  }

  // CONTENIDO
  obtener_contenido(product: string) {
    return this.http.get('assets/data/content_' + product + '.json');
  }
  // Listado países y ciudades
  obtener_json(json: string) {
    return this.http.get(json);
  }

  // Login
  firstAuthn(body:any) {

    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'channel': 'FRONT_MIDC'
    });

    body.country = environment.country;
    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.firstAuthn, body, { headers });

  }

  // Cerrar sesion
  cerrarSesion() {

    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      clientId: this.sesion.codeApp,
      country: environment.country,
      idSession: this.sesion.cookieValue,
    };
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.signOff, body, { headers });
  }

  // Crear sesion de usuario reddis
  saveSession(access_token:any) {

    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': access_token
    });

    const body = JSON.stringify({
      clientId: this.sesion.codeApp,
      country: environment.country
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.saveSession, body, { headers });
  }

  // Validar estado sesion usuario
  validationSession() {

    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = JSON.stringify({
      clientId: this.sesion.codeApp,
      idSession: this.sesion.cookieValue,
      country: environment.country
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.validationSession, body, { headers });
  }

  // Crear usuario nuevo
  registerUser(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'channel': 'FRONT_MIDC'
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.registerUser, body, { headers });
    //return this.http.post("https://ws-nwuser-dev-co-sla-datacash.apps.internal.appcanvas.net/new-user-ws/api/v1/user/CO/registerUser", body, { headers });
  }

  // Validar informacion recuperar contrasena email
  validateInfoUpPass(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    body.clientId = this.sesion.codeApp;
    body.country = environment.country;
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.validateInfoUpPass, body, { headers });
  }

  // Actualizar password
  updatePassword(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    body.clientId = this.sesion.codeApp;
    body.country = environment.country;
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.updateContra, body, { headers });
  }

  // Obtener informacion adicional usuario
  getUserSession(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    body.country = environment.country;

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.getUserSession, body, { headers });
  }

  // Validar si usuario esta registrado en db2 o mongo
  getUser(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });    
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.getUser, body, { headers });
  }

  // Actualizar informacion adicional usuario
  updateProfile(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // tslint:disable-next-line: max-line-length
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.updateProfile, body, { headers });
    //return this.http.post('https://ac-nwuser-ws-demo-sla-datacash.a-internal.appcanvas.net/new-user-ws/api/v1/user/updateProfile', body, { headers });
  }

  // Oneshot
  checkPlanPrice(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.checkPlanPrice, body, { headers });
  }

  oneshotRequest(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.oneshotRequest, body, { headers });
  }

  redimirBaloto(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.oneshotBaloto, body, { headers });
  }

  // Undia
  undiaPromocode(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.undia, body, { headers });
  }

  // REVISAR
  obtener_informacion(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    body.country = environment.country;
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.obtainUser, body, { headers });
  }

  actualizar_adicional(data:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    data.sessionId = this.sesion.getCookie();
    const body = JSON.stringify(data);
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.saveUserInfo, body, { headers });
  }

  verify_seller(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.VerifyEP.varifySeller, body, { headers });
  }

  initPassOTP(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.initPassOTP, body, { headers });
  }

  verifyOTPRecoverPass(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.verifyOTPRecoverPass, body, { headers });
  }

  //Evidente

  //Verificar Usuario
  verifyUser(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.verifyUser, body, { headers });
  }

  // Inicia recuperacion de contrasena evidente
  passRecovery(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.passRecovery, body, { headers });
  }

  // Validar respuestas evidente recuperar contraseña
  passRecoveryVerify(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.passRecoveryVerify, body, { headers });
  }

  // Validar Evidente + OTP
  // validateOTP_customer() {
  //   const headers: any = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });
  //   const body: String = this.sesion.cookieValue;

  //   return this.http.post(environment.urlEndPoints + environment.EvidenteEP.validateOTPCusto, body, { headers });
  // }

  //Generar Codigo OTP
  validateOTP(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.validateOTP, body, { headers });
  }

  //Verificar Codigo OTP
  verifyOTP(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.verifyOTP, body, { headers });
  }

  //Generar Preguntas Evidente 
  questions(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.questions, body, { headers });
  }

  //Validar Preguntas Evidente  
  validateQuestions_User(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.validateQuesUser, body, { headers });
  }

  //Validar respuestas preguntas OTP.  
  validateQuestionsOTP(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.validateQuestionsOTP, body, { headers });
  }

  // Validar Evidente con SessionId
  // validateCustomer(cookie) {
  //   const headers: any = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });
  //   const body: string = cookie;

  //   return this.http.post(environment.urlEndPoints + environment.EvidenteEP.validateCusto, body, { headers });
  // }

  // Validar Preguntas con SessionId
  // validateQuestions_Customer(body) {
  //   const headers: any = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });

  //   return this.http.post(environment.urlEndPoints + environment.EvidenteEP.validateQues, body, { headers });
  // }

  // Valida usuario y obtiene preguntas
  validateUser(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // const url = "https://ws-validation-qa-co-sla-datacash.apps.internal.appcanvas.net/api/v1/evidente/validateUser";
    // return this.http.post(url, body, { headers });
    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.validateUser, body, { headers });
    //return this.http.post('https://ac-validation-ws-demo-sla-datacash.a-internal.appcanvas.net/api/v1/evidente/validateUser', body, { headers });
  }

  // Verifica las preguntas
  verifyQuestions(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // const url = "https://ws-validation-qa-co-sla-datacash.apps.internal.appcanvas.net/api/v1/evidente/validateQuestions";
    // return this.http.post(url, body, { headers });
    return this.http.post(environment.urlEndPoints + environment.EvidenteEP.validateQuesUser, body, { headers });
    //return this.http.post('https://ac-validation-ws-demo-sla-datacash.a-internal.appcanvas.net/api/v1/evidente/validateQuestions', body, { headers });
  }

  // Nuevos Términos y Condiciones
  mostrarTerminos(body:any){
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.findCustomerLog, body, {headers});
    //return this.http.post("https://ui-loadnwuser-dev-co-sla-datacash.apps.internal.appcanvas.net/termsConditions/findCustomerLog", body, {headers});
  }

  /*** Aceptacion de  Nuevos Términos y Condiciones */ 
  guardarTerminos(body:any){
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.addCustomerLog, body, {headers});
    //return this.http.post("https://ui-loadnwuser-dev-co-sla-datacash.apps.internal.appcanvas.net/termsConditions/addCustomerLog", body, {headers});
  }

  // Nuevo SSO
  // Consultar si el usuario existe en nuestra Base de datos.
  consultUser(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'channel': 'FRONT_MIDC'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.consultUser , body ,  { headers });
  }

  /*** Responde la pregunta de seguridad para iniciar sesión. */
  secondAuthn(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'channel': 'FRONT_MIDC'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.secondAuthn , body ,  { headers });
  }

  // Responde la pregunta de seguridad para iniciar sesión.
  questionList(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.questionList , body ,  { headers });
  }

  // Responde la pregunta de seguridad para iniciar sesión.
  updateSecondAuthn(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.updateSecondAuthn , body ,  { headers });
  }

  // Consulta la pregunta de seguridad para recuperar contraseña.
  recoveryQuestion(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.recoveryQuestion , body ,  { headers });
  }

  // Servicio para registrar a un usuario en IAM.
  registerUserIam(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'channel': 'FRONT_MIDC'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.registerUserIam , body ,  { headers });
  }

  // Servicio para añadir contraseña a un usuario creado en IAM - proceso registro
  addPassword(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'channel': 'FRONT_MIDC'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.addContra , body ,  { headers });
  }
  // Servicio para añadir contraseña a un usuario creado en IAM - proceso login
  addPasswordExist(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'channel': 'FRONT_MIDC'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.addContraExist , body ,  { headers });
  }
  // Servicio para iniciar asignacion de contraseña a un usuario creado en IAM - proceso login
  asignPasswordIAM(body:any) {
    const headers: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'channel': 'FRONT_MIDC'
    });
    return this.http.post(environment.urlEndPoints + environment.APIEndPoints.asignPasswordIAM , body ,  { headers });
  }

    // Informacion adicional
    datosUsuario( cookie: string ) {
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      const body = JSON.stringify(
        {
          product: "MIDATACREDITO",
          sessionId: cookie
        }
      );
  
      return this.http.post(environment.urlEndPoints + environment.APIEndPoints.sessioninfo, body, { headers} );
      // return this.http.post('https://ac-ldnfosession-ws-demo-sla-datacash.a-internal.appcanvas.net/sessioninfo/ecs', body, { headers} );
    }

    /*** Servicio para aceptar comunicaciones comerciales */
    aceptarPublicidad(body:any){
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json'
      });
      return this.http.post(environment.urlEndPoints + environment.APIEndPoints.saveAcceptanceAdvertising, body, {headers});
    }

    loadParameters(key:string) {
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'key':key,
        'app': 'SSO'
      });
      return this.http.get(environment.urlEndPoints + environment.APIEndPoints.loadParameters, { headers });
    }

    public getTermsConditions(documentType: string, documentNumber: string, session: string): Observable<any> {
      let urlTerms = `${environment.urlEndPoints}${environment.APIEndPoints.authorization}`;
  
      urlTerms += session === "" ? `${documentType}/${documentNumber}` : `${session}`;
  
      return this.http.get<any>(urlTerms, headers).pipe(tap((_) => null), catchError(this.handleError<any>('getTermsConditions')));
    }
  
    public saveAuthorization(authorization: AuthorizationRequest): Observable<any> {
      const urlSaveAuthorization = `${environment.urlEndPoints}${environment.APIEndPoints.authorization}save`;
      return this.http.post<any>(urlSaveAuthorization, authorization, headers);
    }
  
    public updateAuthorization(authorization: AuthorizationRequest): Observable<any> {
      const urlSaveAuthorization = `${environment.urlEndPoints}${environment.APIEndPoints.authorization}update`;
      return this.http.post<any>(urlSaveAuthorization, authorization, headers);
    }
}