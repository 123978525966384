import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
declare var googletag: any;

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public utmParams: Params = {};
  public haveUtm: boolean = false;
  private gptScriptLoad = false;
  private REFRESH_KEY = 'refresh';
  private REFRESH_VALUE = 'true';

  constructor(public route: ActivatedRoute, public router: Router) { }

  loadGPTscript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.gptScriptLoad) {
        resolve();
      }

      const script = document.createElement('script');
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.async = true;
      script.onload = () => {
        this.gptScriptLoad = true;
        this.setSubRoutinesGPT();
        resolve();
      }
      script.onerror = () => {
        reject(console.error('Error al cargar el script GPT'));
      }
      document.head.appendChild(script);
    });
  }
  /**
   * Este método Inicializa y publica el banner de Google Ad Manager
   * @param slot Id del Ad Unit
   * @param id Id del div
   * @param size Tamaño del banner
   */
  async addPubliById(slot: string, id: string, size: number[][], isTop: boolean = false): Promise<void> {
    try {
      await this.loadGPTscript();
      googletag.cmd.push(() => {

        let mapping = googletag.sizeMapping()
          .addSize([0, 0], [[320, 50], [300, 50], [320, 100], [300, 100], [300, 250], [336, 280]]) // Configuración de view_port
          .addSize([769, 200], [[728, 90], [970, 250]])
          .addSize([1024, 200], [[728, 90], [970, 90], [970, 250]])
          .build();
        if (isTop) {
          mapping = googletag.sizeMapping()
            .addSize([0, 0], [[320, 50], [320, 100]])
            .addSize([980, 200], [[728, 90], [970, 90]])
            .build();
        }

        var slotAdd = googletag.pubads().getSlots().find((id_slot: any) => id_slot.getSlotElementId() === id);
        if (!slotAdd) {
          googletag.defineSlot(slot, size, id).defineSizeMapping(mapping).setTargeting(this.REFRESH_KEY, this.REFRESH_VALUE).setTargeting('refreshed_slot', 'false').addService(googletag.pubads());
          // googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          googletag.display(id);
        } else {
          googletag.pubads().refresh([slotAdd]);
        }
      });
    } catch (error) {
      console.error('Error al inicializar banner', error);
    }
  }


  setSubRoutinesGPT() {
    let util = this;
    googletag.cmd.push(() => {
      var SECONDS_TO_WAIT_AFTER_VIEWABILITY = 30;
      googletag.pubads().addEventListener('impressionViewable', function (event: any) {
        var slot = event.slot;
        if (slot.getTargeting(util.REFRESH_KEY).indexOf(util.REFRESH_VALUE) > -1) {
          slot.setTargeting('refreshed_slot', 'true');
          setTimeout(function () {
            googletag.pubads().refresh([slot]);
          }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
        }
      });

      // Subrotina del LazyLoad
      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 80,
        renderMarginPercent: 80,
        mobileScaling: 1.5
      });

      googletag.enableServices();
    });
  }

  /**
 * Funcion que obtiene y guarda en el sessionstorage los parametros que contengan la utm en su llave
 */
  getUtmParams(setUrl: boolean = true) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.utmParams = {};
        let params = Object.fromEntries((new URLSearchParams(window.location.search) as any).entries());
        for (const key in params) {
          if (key.toLowerCase().includes('utm')) {
            this.utmParams[key] = params[key];
            this.haveUtm = true;
          }
        }
        if (Object.keys(this.utmParams).length !== 0 && this.haveUtm) {
          sessionStorage.setItem('utmParams', JSON.stringify(this.utmParams));
        } else {
          let sessionUtm = JSON.parse(sessionStorage.getItem('utmParams') as string);
          if (sessionUtm != null) {
            this.utmParams = sessionUtm;
            this.haveUtm = true;
            if (this.utmParams != null && setUrl) {
              // setTimeout(() => {
              this.setParams(this.utmParams);
              // }, 100)
            }
          }
        }
      }
    });

  }

  /**
   * Fucnion que toma los parametros de UTM y los devuelve en forma de query string
   * @returns query string UTM
   */
  createUTM() {
    this.getUtmParams(false);
    let params = JSON.parse(sessionStorage.getItem('utmParams') as string);
    let utm = ''
    if (params != null) {
      for (const key in params) {
        utm = utm + key + '=' + params[key] + '&';
      }
      utm = utm.substring(0, utm.length - 1);
    }
    return utm;
  }

  /**
   * Funcion que valida una url y sus parametros para generar una url con query string
   * @param url url al cual se quiere concatenar un query string
   * @param params parametros en formato query string para concatenar a la url
   * @returns url con un query string
   */
  urlWithParams(url: string, params: string) {
    if (params != '') {
      if (url.includes('?')) {
        return url + '&' + params;
      } else {
        return url + '?' + params;
      }
    } else {
      return url;
    }
  }

  /**
   * Funcion para agregar los parametros utm en la url actual
   * @param params Parametros utm para agregar en la url
   */
  setParams(params: Params) {
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: params,
    //   queryParamsHandling: 'merge', // remove to replace all query params by provided
    // }
    // );
    let path = window.location.pathname;
    let hash = window.location.hash;
    let paramsUrl = new URLSearchParams(window.location.search);
    let newParams = new URLSearchParams(params);
    // window.history.replaceState({}, '', `${path}?${paramsUrl.toString()+'&'+newParams.toString()}${hash}`);
    window.history.pushState(null, '', `${path}?${paramsUrl.toString() + '&' + newParams.toString()}${hash}`);
  }

  /**
   * Funcion que valida el producto para generar la url al cual redireccionar con la utm si existe
   * @param product producto al cual se quiere redireccionar
   * @returns url del producto con la utm si existe
   */
  getUrlProduct(product: string) {
    let urlsProduct: any = {
      'midc': this.urlWithParams(environment.validLogin_midc, this.createUTM()),
      'bc': this.urlWithParams(environment.validLogin_bc, this.createUTM()),
      'pntd': this.urlWithParams(environment.validLogin_pntd, this.createUTM()),
      'vrf': this.urlWithParams(environment.validLogin_vrf, this.createUTM())
      // 'os' : environment.validLogin_vrf + '?' + this.createUTM()
      // 'midc' : environment.validLogin_midc + '?' + this.createUTM(),
    }
    return (urlsProduct[product] != undefined ? urlsProduct[product] : urlsProduct['midc']);
  }
}
