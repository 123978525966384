<!-- Banner de Google Ads -->
<div *ngIf="user_type != 'ACTIVO'" id='div-gpt-ad-1694399862191-0' class="commercial-banner"></div>

<div class="container-fluid footer">
  <div class="container">
    <div class="row logo">
      <div class="col-12 col-sm-4">
        <i class="icon-mis-ofertas"></i>
        <div class="logo_txt" *ngIf="content.info.footer?.column1?.logo">
            <img [src]="content.logoURL(content.info.footer?.column1?.logo)" width="90%" height="auto">
        </div>
      </div>
      <div class="mapsite col-12 col-sm-8">
        <ul class="row list-group list-group-flush d-sm-none" *ngIf="content.info.footer?.column2?.enlaces.length > 0">
          <li class="list-group-item">
              <button class="btn btn-link title" data-toggle="collapse" data-target="#midatacash" aria-expanded="false" aria-controls="midatacash">
                  {{ content.info.footer?.column2?.titulo }}
              </button>
              <div id="midatacash" class="collapse">
                <a [href]="enlace.enlace" [target]="enlace.tipo" class="mapsite_link" *ngFor="let enlace of content.info.footer?.column2?.enlaces">
                  {{ enlace.text }}
                </a>
              </div>
          </li>
          <li class="list-group-item" *ngIf="content.info.footer?.column3?.enlaces.length > 0">
              <button class="btn btn-link title" data-toggle="collapse" 
              data-target="#faqfo" aria-expanded="false" 
              aria-controls="faqfo">
                {{ content.info.footer?.column3?.titulo }}
              </button>
              <div id="faqfo" class="collapse">
                <a [href]="enlace.enlace" [target]="enlace.tipo" class="mapsite_link" *ngFor="let enlace of content.info.footer?.column3?.enlaces">
                  {{ enlace.text }}
                </a>
              </div>
          </li>
          <li class="list-group-item" *ngIf="content.info.footer?.column4?.enlaces.length > 0">
              <button class="btn btn-link title" data-toggle="collapse" data-target="#somos" aria-expanded="false" aria-controls="somos">
                {{ content.info.footer?.column4?.titulo }}
              </button>
              <div id="somos" class="collapse">
                <a [href]="enlace.enlace" [target]="enlace.tipo" class="mapsite_link" *ngFor="let enlace of content.info.footer?.column4?.enlaces">
                  {{ enlace.text }}
                </a>
              </div>
          </li>
        </ul>
        <div class="footer-full d-none d-sm-block">
          <div class="row justify-content-around ">
            <div [ngClass]="{'col-sm-4': content.info.footer?.column4?.enlaces.length > 0,'col-sm-6': content.info.footer?.column4?.enlaces.length === 0 }">
              <div class="title">{{ content.info.footer?.column2?.titulo }}</div>
              <a (click)="tracking_datalayer(enlace.text, enlace.enlace)" [target]="enlace.tipo" class="mapsite_link" *ngFor="let enlace of content.info.footer?.column2?.enlaces">
                {{ enlace.text }}
              </a>
            </div>
            <div [ngClass]="{'col-sm-4': content.info.footer?.column4?.enlaces.length > 0,'col-sm-6': content.info.footer?.column4?.enlaces.length === 0 }">
              <div class="title">{{ content.info.footer?.column3?.titulo }}</div>
              <a (click)="tracking_datalayer(enlace.text, enlace.enlace)" [target]="enlace.tipo" class="mapsite_link" *ngFor="let enlace of content.info.footer?.column3?.enlaces">
                {{ enlace.text }}
              </a>
            </div>
            <div class="col-sm-4" *ngIf="content.info.footer?.column4?.enlaces.length > 0" >
              <div class="title">{{ content.info.footer?.column4?.titulo }}</div>
              <a (click)="tracking_datalayer(enlace.text, enlace.enlace)" [target]="enlace.tipo" class="mapsite_link" *ngFor="let enlace of content.info.footer?.column4?.enlaces">
                {{ enlace.text }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="brand p-3">
  <img src="assets/images/Logo-Datacredito.svg" class="my-3"  alt="Datacrédito Experian" width="200">
  <p>
     {{ currentYear + ' Experian Colombia S.A. © Derechos reservados. NIT 900.422.614-8. Carrera 7 No. 76 – 35 Bogotá D.C., Colombia. Email: servicioalciudadano@experian.com' }} 
  </p>
  <p class="version">
    V.a13-1.9
  </p>
</div>